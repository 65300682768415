import React from 'react';

import pageStyle from '../assets/css/page.module.css';
import style from '../assets/css/main.module.css';
import Menu from '../components/menu';
import Footer from '../components/footer';
import HeroPage from '../components/hero.page';
import Hamburger from '../components/hamburger';
import LinkClient from '../components/link.client';
import SEO from '../components/seo';

import imgHero from '../assets/img/heroes/clients.jpg';

import imgToyota from '../assets/img/clients/toyota.png';
import imgLexus from '../assets/img/clients/lexus.png';
import imgFlying from '../assets/img/clients/flyingfish.png';
import imgRight from '../assets/img/clients/rightink.png';
import imgTeag from '../assets/img/clients/teag.png';
import imgEBQuad from '../assets/img/clients/ebquad.png';
import imgRaal from '../assets/img/clients/raal.png';
import imgTimur from '../assets/img/clients/timur.png';
import imgBNI from '../assets/img/clients/bni.png';
import imgSynergie from '../assets/img/clients/synergie.png';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Clients | Agence digitale | MDS Digital Agency"
      keywords="clients"
      description="Découvrez toute la panoplie de clients avec qui nous collaborons. Ils nous font confiance : Toyota, Lexus, EB Quad, Touch English and GO, etc."
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHero} />

    <article className={pageStyle.article} style={{ paddingBottom: '50px' }}>
      <h2>Ils nous font confiance</h2>
      <div className={pageStyle.client_flex}>
        <LinkClient
          name="Toyota Belgium"
          url="https://www.toyota.be"
          img={imgToyota}
          text={[
            <p>
              Nous <strong>maintenons à jour quotidiennement</strong> le site national, le portail
              clients et les sites des concessionnaires{' '}
              <strong>Toyota en Belgique et au Luxembourg</strong>.
            </p>,
            <p>
              Chaque nouvelle information, image, brochure, donnée produit passe entre nos mains.
              Nous veillons à ce que les sites Toyota soient actualisés en permanence.
            </p>,
            <p>
              MDS soigne également le copywriting et l’optimisation du référencement naturel de tout
              le contenu ajouté sur les sites.
            </p>,
            <p>
              Nous veillons au bon fonctionnement des sites et nous développons de nouvelles
              fonctionnalités afin d’améliorer sans cesse l’expérience des utilisateurs.
            </p>
          ]}
        />
        <LinkClient
          name="Lexus Belgium"
          url="https://www.lexus.be"
          img={imgLexus}
          text={[
            <p>
              Nous sommes en charge de la <strong>mise à jour récurrente</strong> des informations
              relatives à l’ensemble des modèles de prestige présentés sur le site de Lexus en
              Belgique et au Luxembourg (brochures, visuels, textes, options, coordonnées des
              garages Lexus,...).
            </p>,
            <p>
              Nous créons des pages d'informations pour annoncer des événements futurs, des séries
              spéciales, des nouveautés, des promotions,...
            </p>,
            <p>
              MDS soigne également le <strong>copywriting</strong> et l’
              <strong>optimisation du référencement naturel</strong> de tout le contenu ajouté sur
              le site.
            </p>
          ]}
        />
        <LinkClient
          name="Right Ink"
          url="https://www.right-ink.com"
          img={imgRight}
          text={[
            <p>
              Nous nous sommes chargés du développement approfondi de fonctionnalités majeures du
              site web, comme par exemple l’amélioration des formulaires et la création d’un blog.
            </p>,
            <p>
              Nous avons, par ailleurs, procédé à une révision de textes existants sur le site Right
              Ink, ce qui nous a permis d’établir une stratégie SEO sur mesure pour la société.
            </p>,
            <p>
              Sur base des objectifs et du budget du client, MDS a également pu mettre en place une
              campagne de référencement payant efficace garantissant un bon retour sur
              investissement. MDS assure également les opérations de maintenance du site internet de
              Right Ink.
            </p>
          ]}
        />
        <LinkClient
          name="Touch English And Go"
          url="https://www.touch-english-and-go.com/"
          img={imgTeag}
          text={[
            <p>
              Nous avons réalisé une <strong>refonte complète</strong> du site internet de{' '}
              <strong>Touch English And Go</strong>. Pour ce faire, nous avons commencé par
              effectuer un audit de la plateforme afin de connaître ses forces et faiblesses.
            </p>,
            <p>
              {' '}
              Nous avons, par ailleurs, procédé à une révision de textes existants sur le site Right
              Ink, ce qui nous a permis d’établir une stratégie SEO sur mesure pour la société.
            </p>,
            <p>
              L’intervention de MDS sur le site internet de Touch English And Go a permis d’éliminer
              les éléments parasites nuisant au bon fonctionnement de l’interface, en vue de
              favoriser une meilleure expérience client.
            </p>
          ]}
        />
        <LinkClient
          name="Flying Fish"
          url="http://www.flyingfish.agency/"
          img={imgFlying}
          text={[
            <p>
              Flying Fish est une agence digitale spécialisée dans le traitement et l’interprétation
              des données numériques pour des fins stratégiques, marketing.
            </p>,
            <p>
              {' '}
              Nous nous chargeons de la gestion quotidienne des
              <strong> implémentations de tags via Google Tag Manager ou Signal Tag</strong> pour
              tous les sites Toyota gérés par Toyota Motor Europe.
            </p>,
            <p>
              Grâce à ces tags, les campagnes marketing sont plus vite implémentées sur les sites
              web et leurs résultats sont monitorés avec plus de précision.
            </p>
          ]}
        />
        <LinkClient
          name="EB Quad"
          url="https://www.ebquad.be/"
          img={imgEBQuad}
          text={[
            <p>
              Nous avons procédé à la création du site internet de EB Quad étape par étape, de
              l’élaboration du design au référencement naturel et Adwords, en passant par la
              création de l’ensemble de contenus présents sur le site internet (copywriting).
            </p>,

            <p>
              EB Quad nous fait confiance et nous confie la gestion de son identité visuelle. MDS se
              charge, par conséquent, de la conception du logo, des cartes de visites et panneaux
              pvc de la société.{' '}
            </p>,

            <p>
              Nous veillons également à l’amélioration continue et à l’actualisation constante des
              informations présentes sur le site EB Quad afin de permettre aux utilisateurs d’être
              sans cesse tenus à jour des dernières nouveautés.{' '}
            </p>
          ]}
        />
      </div>
      <h2>Partenaires</h2>
      <div className={pageStyle.client_flex}>
        <LinkClient name="RAAL" url="https://www.raal.be/" img={imgRaal} />
        <LinkClient
          name="Timour Nikarkhoev"
          url="https://www.facebook.com/Nikarkhoev/"
          img={imgTimur}
        />
      </div>
      <h2>Business Club</h2>
      <div className={pageStyle.client_flex}>
        <LinkClient name="BNI Mons Premium" url="https://bnimonspremium.be" img={imgBNI} />
        <LinkClient name="Synergie" url="http://www.pme-synergie.org/" img={imgSynergie} />
      </div>
    </article>

    <Footer />
  </div>
);
